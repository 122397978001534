import { Alert, CircularProgress, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { mapStackTrace } from "sourcemapped-stacktrace";
import { submitError, SubmitErrorResult } from "../api/api";
import { platform, version } from "../config";
import { useLicense } from "../license";
import { logger } from "../log";
import { ErrorLike } from "../types";
import { useCapgoInfo } from "../update/capgo/hooks";
import { useWakeLock } from "../wakelock";

const log = logger("error");

export default function ReportError({ error }: { error: ErrorLike }) {
  const state = useErrorReporter(error);

  switch (state.state) {
    case "pending":
      return (
        <Alert severity="info" icon={<CircularProgress size="1em" />}>
          <Typography>Fehlerbericht wird gesendet…</Typography>
        </Alert>
      );
    case "reported":
      return (
        <Alert severity="info">
          <strong>Fehlerbericht gesendet.</strong>
          <br />
          Bitte teilen Sie uns die folgende Fehler-ID mit, wenn Sie Ihren Fehler
          melden:
          <br />
          <code>{state.result.id}</code>
        </Alert>
      );
    case "error":
      return (
        <Alert severity="error">
          Fehlerbericht konnte nicht gesendet werden.
        </Alert>
      );
  }
}

type ErrorReporterState =
  | { state: "pending" }
  | { state: "reported"; result: SubmitErrorResult }
  | { state: "error"; error: unknown };

function useErrorReporter(error: ErrorLike): ErrorReporterState {
  const reported = useRef(false);
  const [state, setState] = useState<ErrorReporterState>({ state: "pending" });
  const stack = useStack(error);
  const license = useLicense();
  const wakeLock = useWakeLock();
  const { i18n } = useTranslation();
  const capgo = useCapgoProperties();

  useEffect(() => {
    if (reported.current || !stack || !capgo) return;
    reported.current = true;

    submitError({
      body: {
        platform,
        version,
        license: license?.id,
        properties: {
          useragent: navigator.userAgent,
          "i18n:language": i18n.language,
          "wakelock:active": wakeLock.active,
          "wakelock:type": wakeLock.type,
          ...capgo,
        },
        error: {
          message: error.message,
          stack: stack.join("\n"),
          code: error.code,
        },
      },
    }).then(
      (response) => {
        if (response.data) {
          setState({ state: "reported", result: response.data });
        } else {
          log.error("Error submitting error", response.error);
          setState({ state: "error", error: response.error });
        }
      },
      (error) => {
        log.error("Error submitting error", error);
        setState({ state: "error", error });
      },
    );
  }, [error, capgo, i18n.language, license, stack, wakeLock]);

  return state;
}

function useStack(error: ErrorLike) {
  const [stack, setStack] = useState<string[]>();

  useEffect(() => {
    if (error.stack) {
      try {
        mapStackTrace(error.stack, setStack);
      } catch (e) {
        console.error("Error mapping stack trace:", e);
        setStack(error.stack.split("\n"));
      }
    } else {
      setStack([]);
    }
  }, [error.stack]);

  return stack;
}

function useCapgoProperties(): Record<string, unknown> | undefined {
  const [capgo] = useCapgoInfo();

  if (platform === "web") return {};
  if (Object.values(capgo).some((v) => v.state === "loading")) return;

  return Object.fromEntries(
    Object.entries(capgo)
      .filter(([, v]) => v.state === "ready")
      .map(([k, v]) => [`capgo:${k}`, v.data]),
  );
}
