import { useCallback } from "react";
import { defaultSettings } from "./defaults";
import { useSettingsStore } from "./store";
import type { Settings } from "./types";

export function useSetting<K extends keyof Settings>(key: K): Settings[K] {
  return (
    useSettingsStore((state) => state.settings)[key] ?? getDefaultSetting(key)
  );
}

export function useSetSetting<K extends keyof Settings>(
  key: K,
): (updater: (current: Settings[K]) => Settings[K]) => void {
  const setSetting = useSettingsStore((state) => state.setSetting);
  return useCallback((updater) => setSetting(key, updater), [setSetting, key]);
}

function getDefaultSetting<K extends keyof Settings>(key: K): Settings[K] {
  return defaultSettings[key];
}
