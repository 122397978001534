import { useCallback } from "react";
import { create } from "zustand";
import { development, platform, portalServer } from "./config";
import { clearData } from "./db";
import { LicenseData, Modul, useLicense, useSetLicense } from "./license";
import { logger } from "./log";
import { resetPage } from "./staticFiles";
import { ErrorLike } from "./types";

const log = logger("auth");

// FIXME implement automatic check for license expiry and renewal.
// how often to check whether the license has expired
// const licenseExpiryCheckInterval = 60 * 60 * 1000;

type AuthErrorStore = {
  error?: ErrorLike;
  setError: (error?: ErrorLike) => void;
};

const useAuthErrorStore = create<AuthErrorStore>((set) => ({
  setError: (error) => set({ error }),
}));

export function useAuthError() {
  return useAuthErrorStore((state) => state.error);
}

export function useSignIn(): (licenseKey: string) => Promise<void> {
  const setAuthError = useAuthErrorStore((state) => state.setError);
  const setLicense = useSetLicense();
  const signOut = useSignOut();

  return useCallback(
    async (licenseKey: string) => {
      try {
        setAuthError(undefined);
        const response = await fetch(
          `${portalServer}/get-device.php?DEVICE=${encodeURIComponent(
            licenseKey,
          )}`,
        );
        if (!response.ok)
          throw new Error(`Fehler: ${response.status} ${response.statusText}`);
        const data = await response.json();
        if (data.success === false)
          throw new Error("Ungültiger Lizenzschlüssel");

        const license: LicenseData = {
          ...data,
          missionApiKey: data.missionApiKey || undefined,
          gueltigkeit: data.gueltigkeit
            ? new Date(data.gueltigkeit).getTime()
            : undefined,
          homebase: data.homebase
            ? { latitude: data.homebase.lat, longitude: data.homebase.lng }
            : undefined,
        };

        if (license.gueltigkeit && license.gueltigkeit <= Date.now()) {
          log.info("license has expired, signing out:", license.gueltigkeit);
          await signOut(
            new Error(
              `Ihre Lizenz ist am ${new Date(
                license.gueltigkeit,
              ).toLocaleString()} abgelaufen. Bitte wenden Sie sich an den rescueTABLET-Support.`,
            ),
          );
        } else {
          await setLicense(license);
        }
      } catch (error: any) {
        log.error("Authentication failed:", error);
        setAuthError(error);
      }
    },
    [setAuthError, setLicense, signOut],
  );
}

export function useSignOut(): (reason?: ErrorLike) => Promise<void> {
  const setAuthError = useAuthErrorStore((state) => state.setError);
  const setLicense = useSetLicense();

  return useCallback(
    async (reason) => {
      if (development || reason || platform !== "web") {
        await clearData();
        await setLicense();
        setAuthError(reason);
        window.location.href = "/";
      } else {
        window.location.href = resetPage;
      }
    },
    [setLicense, setAuthError],
  );
}

export function useRenewLicense(): (keepData?: boolean) => Promise<void> {
  const license = useLicense();
  const signIn = useSignIn();

  return useCallback(
    async (keepData) => {
      if (license) {
        if (!keepData)
          await clearData({ keepConfig: true, keepResources: true });
        await signIn(license.id);
      }
    },
    [license, signIn],
  );
}

export function useAuthenticated(): LicenseData {
  const license = useLicense();
  if (!license) throw new Error("Unauthenticated");
  return license;
}

export function useModulEnabled(modul: Modul): boolean {
  const { module } = useAuthenticated();
  return module.includes(modul);
}

/** Throw if the designated module is not available to the current user. */
export function useRequiredModul(modul: Modul): void {
  const enabled = useModulEnabled(modul);
  if (!enabled) throw new Error(`Modul ${modul} ist nicht aktiviert.`);
}

export function useFeatureEnabled(feature: string): boolean {
  const { features } = useAuthenticated();
  if (!features) return false;
  return features.includes(feature);
}
