import { useEffect, useState } from "react";
import { useRequiredContext } from "../utils";
import { Context } from "./context";
import { type SyncManager } from "./manager";
import { type NetworkStatus } from "./network";
import type { SyncModule, SyncModuleDataUpdatedEvent } from "./types";

export function useSyncManager(): SyncManager {
  return useRequiredContext(Context, "SyncManager");
}

export function useSyncModuleRegistration(module: SyncModule<any>) {
  const manager = useSyncManager();
  return useEffect(() => {
    manager.registerModule(module);
  }, [module, manager]);
}

export function useSyncData<T>(moduleId: string): T | undefined {
  const manager = useSyncManager();
  const [data, setData] = useState<T | undefined>(
    manager.moduleData.get(moduleId) as T | undefined,
  );

  useEffect(() => {
    const listener = (event: SyncModuleDataUpdatedEvent) => {
      if (event.moduleId === moduleId) setData(event.data as T);
    };
    manager.on("module-data-updated", listener);
    return () => manager.off("module-data-updated", listener);
  }, [manager, moduleId]);

  return data;
}

export function useNetworkStatus(): NetworkStatus {
  const manager = useSyncManager();
  const [status, setStatus] = useState(manager.networkStatus);

  useEffect(() => {
    const listener = (event: { status: NetworkStatus }) =>
      setStatus(event.status);
    manager.on("network-status-changed", listener);
    return () => {
      manager.off("network-status-changed", listener);
    };
  }, [manager]);

  return status;
}
