import localforage from "localforage";
import cordovaSQLiteDriver from "localforage-cordovasqlitedriver";
import { logger } from "../log";

const log = logger("db");
const databases: { [name: string]: Storage } = {};

export async function getInstance(name: string): Promise<Storage> {
  if (name in databases) return databases[name];
  const db = localforage.createInstance({ name });
  await db.defineDriver(cordovaSQLiteDriver);
  db.setDriver([
    cordovaSQLiteDriver._driver,
    localforage.INDEXEDDB,
    localforage.WEBSQL,
    localforage.LOCALSTORAGE,
  ]);
  return (databases[name] = new Storage(db, name));
}

export async function clearData(
  options: { keepConfig?: boolean; keepResources?: boolean } = {},
) {
  let dbs = Object.values(databases);
  if (options.keepConfig) dbs = dbs.filter((db) => db.name !== "config");
  if (options.keepResources) dbs = dbs.filter((db) => db.name !== "resources");
  await Promise.all(dbs.map((db) => db.clear()));
}

export class Storage {
  constructor(
    private db: typeof localforage,
    public name: string,
  ) {}

  async readData(key: string): Promise<any> {
    const value = await this.db.getItem(key);
    return value !== null ? value : undefined;
  }

  async writeData(key: string, data: any): Promise<void> {
    await this.db.setItem(key, data);
  }

  async deleteData(key: string): Promise<void> {
    await this.db.removeItem(key);
  }

  async listFiles(): Promise<string[]> {
    return this.db.keys();
  }

  async clear(): Promise<void> {
    log.info(`[${this.name}] deleting all data`);
    await this.db.clear();
  }
}
