import { type PersistStorage, type StorageValue } from "zustand/middleware";
import { getInstance } from "./db";

class DatabaseStorage implements PersistStorage<unknown> {
  async getItem(name: string): Promise<StorageValue<unknown> | null> {
    const { database, key } = this.#parseItemKey(name);
    const data = await (await getInstance(database)).readData(key);
    if (data === undefined) return null;
    const state = JSON.parse(data);
    const result = "state" in state && "version" in state ? state : { state };
    if (typeof result.version !== "number") result.version = 0;
    return result;
  }

  async setItem(name: string, value: StorageValue<unknown>): Promise<void> {
    const { database, key } = this.#parseItemKey(name);
    await (await getInstance(database)).writeData(key, JSON.stringify(value));
  }

  async removeItem(name: string): Promise<void> {
    const { database, key } = this.#parseItemKey(name);
    await (await getInstance(database)).deleteData(key);
  }

  #parseItemKey(itemKey: string): { database: string; key: string } {
    const parts = itemKey.split("/", 2);
    return parts.length === 1
      ? { database: "config", key: itemKey }
      : { database: parts[0], key: parts[1] };
  }
}

const storage = new DatabaseStorage();

export function getStorage<TData>(): PersistStorage<TData> {
  return {
    getItem: (name) =>
      storage.getItem(name) as Promise<StorageValue<TData> | null>,
    setItem: (name, value) => storage.setItem(name, value),
    removeItem: (name) => storage.removeItem(name),
  };
}
