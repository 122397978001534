import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Typography,
} from "@mui/material";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { development } from "../config";
import { clearData } from "../db";
import { ErrorLike } from "../types";
import ReportError from "./ErrorReporter";
import SubmitButton from "./SubmitButton";

export type Props = {
  error: ErrorLike;
};

export default function GlobalError({ error }: Props) {
  return development ? (
    <DevelopmentError error={error} />
  ) : (
    <ProductionError error={error} />
  );
}

function ProductionError({ error }: Props) {
  const { t } = useTranslation("error");
  const [resetting, setResetting] = useState(false);
  const reset = useCallback(async () => {
    setResetting(true);
    await clearData({ keepConfig: true, keepResources: true });
    window.location.reload();
  }, []);

  return (
    <Card sx={{ m: 2 }}>
      <CardHeader
        title={t("heading")}
        slotProps={{ title: { color: "error" } }}
      />
      <CardContent>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <Typography sx={{ whiteSpace: "pre-line" }}>{t("info")}</Typography>
          <ReportError error={error} />
        </Box>
      </CardContent>
      <CardActions>
        <SubmitButton onClick={reset} loading={resetting}>
          {t("reload-data")}
        </SubmitButton>
      </CardActions>
    </Card>
  );
}

function DevelopmentError({ error }: Props) {
  return (
    <Card sx={{ m: 2 }}>
      <CardHeader
        title={error.message}
        slotProps={{ title: { color: "error" } }}
      />
      <CardContent>
        <pre>{error.stack}</pre>
      </CardContent>
    </Card>
  );
}
