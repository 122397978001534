import { GlobalCSS } from "@rescuetablet/theme";
import { lazy, Suspense } from "react";
import { HelmetProvider } from "react-helmet-async";
import { configureAppApi } from "./api/configure";
import Delay from "./components/Delay";
import ErrorBoundary from "./components/ErrorBoundary";
import GlobalLoading from "./components/GlobalLoading";
import { apiEndpoint } from "./config";
import "./i18n";
import { useLicense } from "./license";
import { ThemeProvider, useUserTheme } from "./theme";
import { UpdateProvider } from "./update";

const Main = lazy(() => import("./Main"));
const Welcome = lazy(() => import("./pages/welcome"));

configureAppApi({ baseUrl: apiEndpoint });

const fallback = (
  <Delay>
    <GlobalLoading />
  </Delay>
);

export default function App() {
  return (
    <UpdateProvider>
      <ThemeProvider {...useUserTheme()}>
        <GlobalCSS />
        <ErrorBoundary>
          <HelmetProvider>
            <Suspense fallback={fallback}>
              <Content />
            </Suspense>
          </HelmetProvider>
        </ErrorBoundary>
      </ThemeProvider>
    </UpdateProvider>
  );
}

function Content() {
  const license = useLicense();
  return license ? <Main /> : <Welcome />;
}
