import type {
  Mission,
  MissionsManagerEventListener,
} from "@rescuetablet/missions-client";
import { type ManagedMission } from "@rescuetablet/missions-client";
import { useMissionsContext } from "@rescuetablet/missions-client/react";
import { useCallback, useEffect } from "react";
import { create } from "zustand";

type MissionsStore = {
  missions: Record<string, ManagedMission>;
  setMission: (mission: ManagedMission) => void;
  updateMission: (
    id: string,
    updater: (current: ManagedMission) => ManagedMission,
  ) => void;
  removeMission: (id: string) => void;
};

const useMissionsStore = create<MissionsStore>((set) => ({
  missions: {},

  setMission: (mission) =>
    set(({ missions }) => ({
      missions: { ...missions, [mission.id]: mission },
    })),

  updateMission: (id, updater) =>
    set(({ missions }) =>
      id in missions
        ? { missions: { ...missions, [id]: updater(missions[id]) } }
        : {},
    ),

  removeMission: (id) =>
    set(({ missions }) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { [id]: deleted, ...rest } = missions;
      return { missions: rest };
    }),
}));

export function useConnectMissionsStore() {
  const { manager } = useMissionsContext();
  const setMission = useMissionsStore((state) => state.setMission);
  const removeMission = useMissionsStore((state) => state.removeMission);

  useEffect(() => {
    const onMissionAdded: MissionsManagerEventListener<"mission_added"> =
      setMission;

    const onMissionUpdated: MissionsManagerEventListener<"mission_updated"> =
      setMission;

    const onMissionRemoved: MissionsManagerEventListener<"mission_removed"> = ({
      id,
    }) => removeMission(id);

    manager.on("mission_added", onMissionAdded);
    manager.on("mission_updated", onMissionUpdated);
    manager.on("mission_removed", onMissionRemoved);

    return () => {
      manager.off("mission_added", onMissionAdded);
      manager.off("mission_updated", onMissionUpdated);
      manager.off("mission_removed", onMissionRemoved);
    };
  }, [manager, setMission, removeMission]);
}

export function useMissions(): Record<string, ManagedMission> {
  return useMissionsStore((state) => state.missions);
}

export function useAddMission(): (mission: ManagedMission) => void {
  return useMissionsStore((state) => state.setMission);
}

export function useRemoveMission(): (id: string) => void {
  return useMissionsStore((state) => state.removeMission);
}

export function useUpdateMissionById(): (
  missionId: string,
  updater: (mission: Mission) => Mission,
) => void {
  const updateMission = useMissionsStore((state) => state.updateMission);

  return useCallback(
    (missionId, updater) => {
      updateMission(missionId, (mission) => {
        if (mission?.state === "ready") {
          const { resources, ...updated } = updater(mission.mission);
          return {
            ...mission,
            mission: {
              ...updated,
              resources: resources.filter((r) => !r.removed),
              removedResources: resources.filter((r) => r.removed),
              stale: mission.mission.stale,
            },
          };
        }
        return mission;
      });
    },
    [updateMission],
  );
}
