import { create } from "zustand";
import { persist, type PersistOptions } from "zustand/middleware";
import { getStorage } from "../db/zustand";
import type { LicenseData } from "./types";

type LicenseStoreData = {
  license?: LicenseData;
};

type LicenseStore = LicenseStoreData & {
  setLicense: (license?: LicenseData) => void;
};

const persistOptions: PersistOptions<LicenseStore, LicenseStoreData> = {
  name: "config/license",
  storage: getStorage(),
  version: 1,
  migrate: (state, version) => {
    console.log("migrate license:", { state, version });
    switch (version) {
      case 0:
        return { license: state as LicenseData };
      default:
        return {};
    }
  },
};

export const useLicenseStore = create(
  persist(
    (set) => ({
      setLicense: (license) => set({ license }),
    }),
    persistOptions,
  ),
);
