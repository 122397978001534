import { AbortController, AbortSignal } from "abort-controller";
import "core-js/features/array/flat-map";
import "core-js/features/array/includes";
import "core-js/features/number/is-nan";
import "core-js/features/promise/with-resolvers";

if (typeof window.AbortController === "undefined")
  window.AbortController = AbortController as any;
if (typeof window.AbortSignal === "undefined")
  window.AbortSignal = AbortSignal as any;
