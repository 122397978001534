import { create } from "zustand";
import { type PersistOptions, persist } from "zustand/middleware";
import { getStorage } from "../../db/zustand";
import { trackEvent } from "../../tracking";
import { defaultSettings } from "./defaults";
import type { Settings } from "./types";

type SettingsStoreData = { settings: Settings };

type SettingsStore = SettingsStoreData & {
  setSetting<K extends keyof Settings>(
    key: K,
    updater: (value: Settings[K]) => Settings[K],
  ): void;
};

const persistOptions: PersistOptions<SettingsStore, SettingsStoreData> = {
  name: "config/settings",
  storage: getStorage(),
  version: 1,
  migrate: (state, version) => {
    console.info("migrate settings:", { state, version });
    switch (version) {
      case 0:
        return { settings: state as Settings };
      default:
        return { settings: defaultSettings };
    }
  },
};

export const useSettingsStore = create(
  persist(
    (set) => ({
      settings: defaultSettings,
      setSetting: (key, updater) =>
        set((state) => {
          const updated = updater(state.settings[key]);

          trackEvent(`Setting: ${key}`, {
            category: "Settings",
            label: updated?.toString(),
          });

          return { settings: { ...state.settings, [key]: updated } };
        }),
    }),
    persistOptions,
  ),
);
