import { useMemo } from "react";
import { useAuthenticated } from "../auth";
import { portalServer } from "../config";
import { SyncModule, useSyncData, useSyncModuleRegistration } from "../sync";
import { KartenObjekt, KartenObjektLink, RisikoKlasse } from "../types";
import { limiter } from "./demo";
import { daily } from "./provider";

const syncModuleId = "kartenobjekte";

function useKartenobjekteSyncModule(): SyncModule<ReadonlyArray<KartenObjekt>> {
  const { portalKey, demo } = useAuthenticated();

  return useMemo(
    () => ({
      id: syncModuleId,
      i18nKey: `karte:tasks.${syncModuleId}`,
      defaultOptions: {
        schedule: { type: "periodic", interval: daily },
      },
      fetchData: async () => {
        const response = await fetch(
          `${portalServer}/get-objects-v2.php?ORG_ACCESSKEY=${encodeURIComponent(
            portalKey,
          )}`,
        );

        if (!response.ok) {
          throw new Error(
            `Error fetching data: ${response.status} ${response.statusText}`,
          );
        }

        const data = await response.json();
        const limited = limiter<KartenObjekt>(demo, 20);
        return limited(data.OBJEKT_LIST ?? []).map(parseKartenObjekt);
      },
    }),
    [demo, portalKey],
  );
}

export function useKartenobjekteSync() {
  const module = useKartenobjekteSyncModule();
  useSyncModuleRegistration(module);
}

export function useKartenObjekte(): ReadonlyArray<KartenObjekt> {
  return useSyncData(syncModuleId) ?? [];
}

function parseKartenObjekt(entry: any): KartenObjekt {
  return {
    id: entry.OBJEKT_ID,
    typ: "objekt",
    latitude: parseFloat(entry.OBJEKT_GEO_LAT),
    longitude: parseFloat(entry.OBJEKT_GEO_LNG),
    name: entry.OBJEKT_NAME,
    aliase: entry.OBJEKT_ALIASE || undefined,
    beschreibung: entry.OBJEKT_DESCRIPTION || undefined,
    link: entry.OBJEKT_LINK || undefined,
    adresse: entry.OBJEKT_ADRESSE || undefined,
    einsatzplan: entry.OBJEKT_NR_FWEP || undefined,
    interneNummer: entry.OBJEKT_NR_INTERNAL || undefined,
    risikoKlasse: parseRisikoKlasse(entry.OBJEKT_RISIKO),
    owned: entry.OBJEKT_OWN,
    secondaryLink: parseSecondaryLink(entry),
  };
}

const risikoKlassen: RisikoKlasse[] = ["keine", "niedrig", "mittel", "hoch"];

function parseRisikoKlasse(id?: string): RisikoKlasse | undefined {
  if (!id || id === "0") return undefined;
  return risikoKlassen[parseInt(id, 10)];
}

function parseSecondaryLink(entry: any): KartenObjektLink | undefined {
  const url = entry.OBJEKT_LINK2;
  if (!url) return;

  return { url, label: entry.OBJEKT_LINK2DESC || undefined };
}
