import { create } from "zustand";
import type { ErrorLike, Koordinaten } from "../../types";

export type LocationContext =
  | { state: "pending" }
  | {
      state: "available";
      koordinaten: Koordinaten;
      accuracy: number;
      updatedAt: number;
    }
  | { state: "error"; error: ErrorLike; updatedAt: number };

type LocationStore = LocationContext & {
  setLocation: (location: LocationContext) => void;
};

export const useLocationStore = create<LocationStore>((set) => ({
  state: "pending",
  setLocation: (location) => set(location),
}));
