import { FirebaseFirestore } from "@capacitor-firebase/firestore";
import { Capacitor } from "@capacitor/core";
import type {
  Identifiable,
  PatientCreatedEvent,
  PatientDeletedEvent,
  PatientUpdatedEvent,
  RemoteMission,
} from "@rescuetablet/missions-client";
import { useMissions } from "@rescuetablet/missions-client/react";
import { collection, onSnapshot } from "firebase/firestore";
import { useEffect, useMemo, useState } from "react";
import { create } from "zustand";
import { notEmpty } from "../utils";
import { log } from "./config";
import { useFirestore } from "./hooks";

export type ActiveMission = {
  missionId: string;
  sectionId?: string;
  pinnedSectionId?: string;
  pinnedResourceId?: string;
};

type ActiveMissionStore = {
  active?: ActiveMission;
  setActiveMission: (
    updater: (current?: ActiveMission) => ActiveMission | undefined,
  ) => void;
};

const useActiveMissionStore = create<ActiveMissionStore>((set) => ({
  setActiveMission: (updater) =>
    set(({ active }) => ({ active: updater(active) })),
}));

export function useActiveMission(): ActiveMission | undefined {
  return useActiveMissionStore((store) => store.active);
}

export function useSetActiveMission(): (
  updater: (current?: ActiveMission) => ActiveMission | undefined,
) => void {
  return useActiveMissionStore((store) => store.setActiveMission);
}

export function useCurrentMission(): RemoteMission | undefined {
  const missions = useMissions();
  const active = useActiveMission();
  const mission = active ? missions[active.missionId] : undefined;
  return mission?.state === "ready" ? mission.mission : undefined;
}

export function useLoadedMissions(): ReadonlyArray<RemoteMission> {
  const missions = useMissions();

  return useMemo(
    () =>
      Object.values(missions)
        .map((m) => (m.state === "ready" ? m.mission : undefined))
        .filter(notEmpty),
    [missions],
  );
}

export function useTrackActiveMission(missionId: string) {
  const setActiveMission = useSetActiveMission();

  useEffect(() => {
    setActiveMission((active) =>
      active?.missionId === missionId ? active : { missionId },
    );
  }, [missionId, setActiveMission]);
}

export type PatientEvent = Identifiable &
  (PatientCreatedEvent | PatientUpdatedEvent | PatientDeletedEvent);

export const usePatientEvents = Capacitor.isNativePlatform()
  ? usePatientEventsNative
  : usePatientEventsWeb;

function usePatientEventsNative(
  missionId: string,
  patientId: string,
): ReadonlyArray<PatientEvent> {
  const [events, setEvents] = useState<Array<PatientEvent>>([]);

  useEffect(() => {
    const callbackIds: Array<string> = [];
    const ref = `missions/${missionId}/patients/${patientId}/events`;

    FirebaseFirestore.addCollectionSnapshotListener(
      { reference: ref },
      (snapshot, error) => {
        if (error) {
          log.error(
            "Error subscribing to events of patient %s / %s:",
            missionId,
            patientId,
            error,
          );
        }

        setEvents(
          (snapshot?.snapshots ?? []).map(
            (doc) => ({ id: doc.id, ...doc.data }) as PatientEvent,
          ),
        );
      },
    ).then((callbackId) => {
      callbackIds.push(callbackId);
    });

    return () => {
      callbackIds.forEach((callbackId) =>
        FirebaseFirestore.removeSnapshotListener({ callbackId }),
      );
    };
  }, [missionId, patientId]);

  return events;
}

function usePatientEventsWeb(
  missionId: string,
  patientId: string,
): ReadonlyArray<PatientEvent> {
  const firestore = useFirestore();
  const [events, setEvents] = useState<Array<PatientEvent>>([]);

  useEffect(() => {
    if (firestore) {
      const coll = collection(
        firestore,
        "missions",
        missionId,
        "patients",
        patientId,
        "events",
      );

      return onSnapshot(
        coll,
        (snapshot) =>
          setEvents(
            snapshot.docs.map(
              (doc) => ({ id: doc.id, ...doc.data() }) as PatientEvent,
            ),
          ),
        (error) => log.error("Error loading patient events:", error),
      );
    }
  }, [firestore, missionId, patientId]);

  return events;
}
