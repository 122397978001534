import cz from "./cz.json";
import de from "./de.json";
import en from "./en.json";
import es from "./es.json";
import fr from "./fr.json";
import gr from "./gr.json";
import hr from "./hr.json";
import hu from "./hu.json";
import it from "./it.json";
import lx from "./lx.json";
import nl from "./nl.json";
import pl from "./pl.json";
import sv from "./sv.json";

export const resources: Record<
  string,
  Record<string, Record<string, unknown>>
> = { cz, de, en, es, fr, gr, hr, hu, it, lx, nl, pl, sv };

export const languages = [
  { code: "cz", completeness: 0.7605633802816901 },
  { code: "de", completeness: 1 },
  { code: "en", completeness: 1 },
  { code: "es", completeness: 0.7605633802816901 },
  { code: "fr", completeness: 0.7605633802816901 },
  { code: "gr", completeness: 0.7605633802816901 },
  { code: "hr", completeness: 0.7605633802816901 },
  { code: "hu", completeness: 0.7605633802816901 },
  { code: "it", completeness: 0.7605633802816901 },
  { code: "lx", completeness: 0.7605633802816901 },
  { code: "nl", completeness: 0.7605633802816901 },
  { code: "pl", completeness: 0.7605633802816901 },
  { code: "sv", completeness: 0.7605633802816901 },
];
