import {
  CapacitorUpdater,
  type BuiltinVersion,
  type CurrentBundleResult,
  type DeviceId,
  type GetChannelRes,
  type LatestVersion,
  type PluginVersion,
} from "@capgo/capacitor-updater";
import { useCallback, useEffect, useState } from "react";
import { logger } from "../../log";
import { ErrorLike } from "../../types";
import { useCreateUpdateInfo } from "../hooks";

const log = logger("capgo");

export function useRegisterCapGo() {
  useEffect(() => {
    registerListeners();

    log.info("notifyAppReady");
    CapacitorUpdater.notifyAppReady()
      .then((result) =>
        log.info("notifyAppReady success: " + JSON.stringify(result)),
      )
      .catch((error) => log.error("notifyAppReady error: " + error.message));
  }, []);
}

export function useUpdateAvailableListener() {
  const createUpdateInfo = useCreateUpdateInfo();

  useEffect(() => {
    CapacitorUpdater.addListener("updateAvailable", (res) => {
      log.info("updateAvailable");
      createUpdateInfo(() => CapacitorUpdater.set(res.bundle));
    });
  }, [createUpdateInfo]);
}

async function registerListeners(): Promise<() => void> {
  const subscriptions = await Promise.all([
    CapacitorUpdater.addListener("download", (event) =>
      log.info("download: " + JSON.stringify(event)),
    ),
    CapacitorUpdater.addListener("noNeedUpdate", (event) =>
      log.info("noNeedUpdate: " + JSON.stringify(event)),
    ),
    CapacitorUpdater.addListener("updateAvailable", (event) =>
      log.info("updateAvailable: " + JSON.stringify(event)),
    ),
    CapacitorUpdater.addListener("downloadComplete", (event) =>
      log.info("downloadComplete: " + JSON.stringify(event)),
    ),
    CapacitorUpdater.addListener("majorAvailable", (event) =>
      log.info("majorAvailable: " + JSON.stringify(event)),
    ),
    CapacitorUpdater.addListener("updateFailed", (event) =>
      log.info("updateFailed: " + JSON.stringify(event)),
    ),
    CapacitorUpdater.addListener("downloadFailed", (event) =>
      log.info("downloadFailed: " + JSON.stringify(event)),
    ),
    CapacitorUpdater.addListener("appReloaded", () => log.info("appReloaded")),
    CapacitorUpdater.addListener("appReady", (event) =>
      log.info("appReady: " + JSON.stringify(event)),
    ),
  ]);

  return async () => Promise.allSettled(subscriptions.map((s) => s.remove()));
}

export function useCapgoInfo(): [
  {
    current: Resource<CurrentBundleResult>;
    channel: Resource<GetChannelRes>;
    deviceId: Resource<DeviceId>;
    latest: Resource<LatestVersion>;
    builtinVersion: Resource<BuiltinVersion>;
    pluginVersion: Resource<PluginVersion>;
  },
  () => void,
] {
  const [current, updateCurrent] = useResource(() =>
    CapacitorUpdater.current(),
  );
  const [channel, updateChannel] = useResource(() =>
    CapacitorUpdater.getChannel(),
  );
  const [deviceId, updateDeviceId] = useResource(() =>
    CapacitorUpdater.getDeviceId(),
  );
  const [latest, updateLatest] = useResource(() =>
    CapacitorUpdater.getLatest(),
  );
  const [builtinVersion, updateBuiltinVersion] = useResource(() =>
    CapacitorUpdater.getBuiltinVersion(),
  );
  const [pluginVersion, updatePluginVersion] = useResource(() =>
    CapacitorUpdater.getPluginVersion(),
  );

  const update = useCallback(
    () =>
      Promise.allSettled([
        updateCurrent(),
        updateChannel(),
        updateDeviceId(),
        updateLatest(),
        updateBuiltinVersion(),
        updatePluginVersion(),
      ]),
    [
      updateChannel,
      updateCurrent,
      updateDeviceId,
      updateLatest,
      updateBuiltinVersion,
      updatePluginVersion,
    ],
  );

  return [
    { current, channel, deviceId, latest, builtinVersion, pluginVersion },
    update,
  ];
}

export type Resource<T> =
  | { state: "loading"; data?: T }
  | { state: "error"; error: ErrorLike; data?: T }
  | { state: "ready"; data: T };

function useResource<T>(loader: () => Promise<T>): [Resource<T>, () => void] {
  const [value, setValue] = useState<Resource<T>>({
    state: "loading",
  });

  const update = useCallback(async () => {
    try {
      const value = await loader();
      setValue({ state: "ready", data: value });
    } catch (error: any) {
      setValue({ state: "error", error });
    }
  }, [loader]);

  useEffect(() => {
    update();
  }, [update]);

  return [value, update];
}
