import { Capacitor } from "@capacitor/core";

export const apiEndpoint =
  import.meta.env.VITE_API_ENDPOINT ?? "https://app-api.rescuetablet.com";
export const portalServer =
  import.meta.env.VITE_PORTAL_SERVER ??
  "https://datenportal.rescuetablet.de/sync/hybrid";
export const missionsServer =
  import.meta.env.VITE_MISSIONS_SERVER ??
  "https://missions-api.rescuetablet.com";

export const version = import.meta.env.VITE_VERSION ?? "development";
export const buildTimestamp = import.meta.env.VITE_BUILD_TIMESTAMP
  ? new Date(parseInt(import.meta.env.VITE_BUILD_TIMESTAMP, 10))
  : new Date();
export const commit = import.meta.env.VITE_COMMIT;
export const platform = Capacitor.getPlatform();
export const preview: string | undefined = import.meta.env.VITE_PREVIEW;

// how often to report own position (in ms)
export const locationSubmissionInterval = 20000;

// how often to fetch position of other devices (in ms)
export const locationsUpdateInterval = 20000;

// max accuracy (in meters) to consider a geolocation valid
export const accuracyValidThreshold = 300;

// min accuracy (in meters) to display accuracy circle
export const accuracyCircleThreshold = 10;

// activate screensaver after this time (in ms)
export const screensaverTimeout = 300000;

export const production = import.meta.env.PROD;
export const development = import.meta.env.DEV;

export const enablePrecache = production;
export const logLevel =
  import.meta.env.VITE_DEBUG === "true" || development ? "verbose" : "info";
