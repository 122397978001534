import { createClient } from "@hey-api/client-fetch";
import { getTranslations } from "@rescuetablet/missions-client";
import { type Locale, setDefaultOptions } from "date-fns";
import i18next from "i18next";
import languageDetector from "i18next-browser-languagedetector";
import { useCallback, useMemo } from "react";
import { initReactI18next, useTranslation } from "react-i18next";
import { missionsServer } from "../config";
import { logger } from "../log";
import { trackEvent } from "../tracking";
import { formatDuration } from "../utils";
import { languages, resources } from "./languages";

const log = logger("i18n");
const defaultLanguage = "de";

const dateFnsLocales: Record<string, Promise<Locale>> = {
  en: import("date-fns/locale/en-US").then(({ enUS }) => enUS),
  de: import("date-fns/locale/de").then(({ de }) => de),
  es: import("date-fns/locale/es").then(({ es }) => es),
  fr: import("date-fns/locale/fr").then(({ fr }) => fr),
  nl: import("date-fns/locale/nl").then(({ nl }) => nl),
  it: import("date-fns/locale/it").then(({ it }) => it),
  pl: import("date-fns/locale/pl").then(({ pl }) => pl),
  // cz: import("date-fns/locale/cz").then(({ cz }) => cz),
  // lx: import("date-fns/locale/lx").then(({ lx }) => lx),
  hu: import("date-fns/locale/hu").then(({ hu }) => hu),
  // gr: import("date-fns/locale/gr").then(({ gr }) => gr),
  sv: import("date-fns/locale/sv").then(({ sv }) => sv),
  hr: import("date-fns/locale/hr").then(({ hr }) => hr),
};

const knownMissingKeys = new Set<string>();

const supportedLngs = languages.map((l) => l.code);

const i18n = i18next.createInstance({
  ns: "common",
  defaultNS: "common",
  fallbackLng: defaultLanguage,
  fallbackNS: "common",
  supportedLngs,
  resources,
  interpolation: {
    escapeValue: false,
    format: (value, format, lng) => {
      const t = i18n.t.bind(i18n);
      if (value instanceof Date) {
        if (format === "date") return value.toLocaleDateString(lng);
        if (format === "datetime") return value.toLocaleString(lng);
        if (format === "duration") return formatDuration(value.getTime(), t);
        return value.toLocaleString(lng);
      }
      if (typeof value === "number") {
        if (format === "int")
          return value.toLocaleString(lng, { maximumFractionDigits: 0 });
        if (format === "date") return new Date(value).toLocaleDateString(lng);
        if (format === "duration") return formatDuration(value, t);
      }
      return value;
    },
  },
  saveMissing: import.meta.env.NODE_ENV === "development",
  missingKeyHandler: (lng, ns, key) => {
    const cacheKey = `${ns}:${key}:${lng}`;
    if (knownMissingKeys.has(cacheKey)) return;
    knownMissingKeys.add(cacheKey);
    log.warn(`Missing translation in ${lng}: ${ns}/${key}`);
  },
});

i18n.use(languageDetector).use(initReactI18next);
i18n.on("languageChanged", (lang) => loadMissionApiResources(lang));
i18n.on("languageChanged", (lang) => {
  const useLang = lang in dateFnsLocales ? lang : "en";
  dateFnsLocales[useLang].then((locale) => setDefaultOptions({ locale }));
});
i18n.init();

async function loadMissionApiResources(lang: string) {
  log.info(`Loading mission-api translations in ${lang}...`);
  try {
    const { data, error } = await getTranslations({
      client: createClient({ baseUrl: missionsServer }),
      path: { language: lang },
    });
    if (error) throw error;

    log.info(`Loaded mission-api translations in ${lang}.`);
    i18n.addResourceBundle(lang, "mission-api", data);
  } catch (e) {
    log.error(`Error loading mission-api translations in ${lang}: ${e}`);
  }
}

export function useActiveLanguage() {
  const { i18n } = useTranslation();

  return useMemo(
    () => languages.find((l) => l.code === i18n.language)!,
    [i18n.language],
  );
}

export function useLanguages() {
  const { i18n } = useTranslation();

  return useMemo(
    () =>
      languages.map((lang) => ({
        code: lang.code,
        completeness: lang.completeness,
        active: lang.code === i18n.language,
      })),
    [i18n.language],
  );
}

export function useSetLanguage() {
  const { i18n } = useTranslation();

  return useCallback(
    (code: string) => {
      if (!languages.some((l) => l.code === code))
        throw new Error(`Unknown language: ${code}`);
      trackEvent("Set language", { category: "Settings", label: code });
      window.localStorage.setItem("i18nextLng", code);
      return i18n.changeLanguage(code);
    },
    [i18n],
  );
}

export function useCapitalize() {
  const {
    i18n: { language },
  } = useTranslation();
  return useCallback((s: string) => capitalize(s, language), [language]);
}

export function capitalize(s: string, locale?: string): string {
  if (!s.length) return s;
  if (s.length === 1) return s.toLocaleUpperCase(locale);
  return s[0].toLocaleUpperCase(locale) + s.slice(1);
}

export default i18n;
