import {
  TextToSpeech,
  type TTSOptions,
} from "@capacitor-community/text-to-speech";
import PQueue from "p-queue";
import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { logger } from "../../log";
import { useTrackEvent } from "../../tracking";
import { useInterval } from "../../utils";
import { useSetting } from "../settings";
import { useSpeechOptionsStore } from "./store";
import type { SpeechOptions } from "./types";

const log = logger("speech");

const speechQueue = new PQueue({ concurrency: 1 });
const optionsRefreshInterval = 1000 * 60;

export function useInitializeSpeech() {
  const update = useUpdateSpeechOptions();
  useEffect(update, [update]);
  useInterval(update, optionsRefreshInterval);
}

function useUpdateSpeechOptions() {
  const setVoices = useSpeechOptionsStore((state) => state.setVoices);

  return useCallback(() => {
    TextToSpeech.getSupportedVoices()
      .then(({ voices }) => setVoices(voices))
      .catch((err) => console.error("Error getting speech options:", err));
  }, [setVoices]);
}

export function useSpeechOptions(): SpeechOptions {
  return useSpeechOptionsStore();
}

export function useSpeak(): (
  text: string,
  options?: Omit<TTSOptions, "text">,
) => Promise<void> {
  const handler = useSpeakHandler();

  return useCallback(
    async (text, options) => speechQueue.add(() => handler(text, options)),
    [handler],
  );
}

function useSpeakHandler(): (
  text: string,
  options?: Omit<TTSOptions, "text">,
) => Promise<void> {
  const config = useSpeechConfig();
  const trackEvent = useTrackEvent();

  return useCallback(
    async (text, options) => {
      log.info(`Speaking "${text}"`);
      trackEvent("Speech start");

      try {
        await TextToSpeech.speak({ ...config, ...options, text });
        log.info(`Done speaking "${text}".`);
        trackEvent("Speech done");
      } catch (e: unknown) {
        log.error(`Error speaking "${text}":`, e);
        trackEvent("Speech error");
      }
    },
    [config, trackEvent],
  );
}

function useSpeechConfig(): Pick<TTSOptions, "voice" | "lang" | "rate"> {
  const options = useSpeechOptions();
  const speechVoiceURI = useSetting("speechVoiceURI");
  const voice = speechVoiceURI
    ? options.voices.findIndex((v) => v.voiceURI === speechVoiceURI)
    : undefined;
  const {
    i18n: { language },
  } = useTranslation();
  const rate = useSetting("speechRate") ?? 1.2;

  return {
    voice: voice !== undefined && voice >= 0 ? voice : undefined,
    lang: language,
    rate,
  };
}
