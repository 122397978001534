import { useMemo } from "react";
import { useAuthenticated } from "../auth";
import { portalServer } from "../config";
import {
  type SyncModule,
  useSyncData,
  useSyncModuleRegistration,
} from "../sync";
import type { KartenObjekt } from "../types";
import { limiter } from "./demo";
import { daily } from "./provider";

const syncModuleId = "kartendetailobjekte";

function useKartenDetailObjekteSyncModule(): SyncModule<
  ReadonlyArray<KartenObjekt>
> {
  const { portalKey, demo } = useAuthenticated();

  return useMemo(
    () => ({
      id: syncModuleId,
      i18nKey: `karte:tasks.${syncModuleId}`,
      defaultOptions: {
        schedule: { type: "periodic", interval: daily },
      },
      fetchData: async () => {
        const response = await fetch(
          `${portalServer}/get-detailobjects.php?ORG_ACCESSKEY=${encodeURIComponent(
            portalKey,
          )}`,
        );

        if (!response.ok) {
          throw new Error(
            `Error fetching data: ${response.status} ${response.statusText}`,
          );
        }

        const data = await response.json();
        const limited = limiter<KartenObjekt>(demo, 100);
        return limited(data.DETAILOBJEKT_LIST ?? []).map(
          parseKartenDetailobjekt,
        );
      },
    }),
    [demo, portalKey],
  );
}

export function useKartenDetailObjekteSync() {
  const module = useKartenDetailObjekteSyncModule();
  useSyncModuleRegistration(module);
}

export function useKartenDetailObjekte(): ReadonlyArray<KartenObjekt> {
  return useSyncData(syncModuleId) ?? [];
}

function parseKartenDetailobjekt(entry: any): KartenObjekt {
  return {
    id: entry.DETAIL_ID,
    typ: entry.DETAIL_ICON.replace(/^map_icon_/, ""),
    latitude: parseFloat(entry.DETAIL_GEO_LAT),
    longitude: parseFloat(entry.DETAIL_GEO_LNG),
    name: entry.DETAIL_NAME,
    beschreibung: entry.DETAIL_DESCRIPTION || undefined,
    color: entry.DETAIL_COLOR || undefined,
    polygon: entry.DETAIL_BLOCK ? parsePolygon(entry.DETAIL_BLOCK) : undefined,
  };
}

function parsePolygon(polygon: string): Array<[number, number]> {
  const nodes = polygon.split(/\s*;\s*/);
  return nodes.map(
    (node) => node.split(/\s*,\s*/).map(parseFloat) as [number, number],
  );
}
