import { useLicenseStore } from "./store";
import type { LicenseData } from "./types";

export function useLicense(): LicenseData | undefined {
  return useLicenseStore((state) => state.license);
}

export function useSetLicense(): (license?: LicenseData) => void {
  return useLicenseStore((state) => state.setLicense);
}
