import { Capacitor, type PluginListenerHandle } from "@capacitor/core";
import { Network } from "@capacitor/network";
import { logger } from "../log";

const log = logger("network");

export const networkTypeValues = [
  "wifi",
  "cellular",
  "none",
  "unknown",
] as const;

export type NetworkType = (typeof networkTypeValues)[number];

export type Warning = "unsupported-api";

export type NetworkStatus = {
  connectionType: string;
  effectiveType: NetworkType;
  warnings?: ReadonlyArray<Warning>;
};

export type NetworkStatusListener = (status: NetworkStatus) => void;

export type NetworkStatusUpdatedEvent = {
  oldStatus?: NetworkStatus;
  status: NetworkStatus;
};

export type NetworkStatusEventTypes = {
  update: NetworkStatusUpdatedEvent;
};

export type NetworkStatusEventListener<
  T extends keyof NetworkStatusEventTypes,
> = (event: NetworkStatusEventTypes[T]) => void;

export interface NetworkStatusObserver {
  readonly status: NetworkStatus;
  start(): void;
  update(): void;
  close(): void;
  setStatus(status: NetworkStatus): void;
  on<T extends keyof NetworkStatusEventTypes>(
    type: T,
    listener: NetworkStatusEventListener<T>,
  ): void;
  off<T extends keyof NetworkStatusEventTypes>(
    type: T,
    listener: NetworkStatusEventListener<T>,
  ): void;
}

export function observeNetworkStatus(): NetworkStatusObserver {
  const observer = createObserver();
  observer.start();
  return observer;
}

function createObserver(): NetworkStatusObserver {
  if (Capacitor.isNativePlatform()) {
    log.info("Using Capacitor network status observer");
    return new CapacitorNetworkStatusObserver();
  }

  log.info("Using web network status observer");
  return new WebNetworkStatusObserver();
}

abstract class NetworkStatusObserverSupport {
  private readonly listeners = new Map<
    string,
    Set<NetworkStatusEventListener<any>>
  >();

  status: NetworkStatus = {
    connectionType: "unknown",
    effectiveType: "unknown",
  };

  setStatus(status: NetworkStatus) {
    if (
      status.connectionType === this.status.connectionType &&
      status.effectiveType === this.status.effectiveType
    ) {
      return;
    }

    const oldStatus = this.status;
    this.status = status;
    log.info(
      `Network status updated from [${oldStatus.connectionType}/${oldStatus.effectiveType}] to [${status.connectionType}/${status.effectiveType}]`,
    );
    this.emit("update", { oldStatus, status });
  }

  on<T extends keyof NetworkStatusEventTypes>(
    type: T,
    listener: NetworkStatusEventListener<T>,
  ) {
    let listeners = this.listeners.get(type);
    if (!listeners) {
      listeners = new Set<NetworkStatusEventListener<any>>();
      this.listeners.set(type, listeners);
    }
    listeners.add(listener);
    listener({ status: this.status });
  }

  off<T extends keyof NetworkStatusEventTypes>(
    type: T,
    listener: NetworkStatusEventListener<T>,
  ) {
    const listeners = this.listeners.get(type);
    if (listeners) listeners.delete(listener);
  }

  private emit<T extends keyof NetworkStatusEventTypes>(
    type: T,
    event: NetworkStatusEventTypes[T],
  ) {
    const listeners = this.listeners.get(type);
    if (listeners) {
      for (const listener of listeners) {
        listener(event);
      }
    }
  }
}

class CapacitorNetworkStatusObserver
  extends NetworkStatusObserverSupport
  implements NetworkStatusObserver
{
  private subscription?: Promise<PluginListenerHandle>;

  start() {
    this.subscription = Network.addListener(
      "networkStatusChange",
      ({ connectionType }) => {
        this.setStatus({ connectionType, effectiveType: connectionType });
      },
    );

    this.update();
  }

  update(): void {
    Network.getStatus().then(({ connectionType }) => {
      this.setStatus({ connectionType, effectiveType: connectionType });
    });
  }

  close(): void {
    this.subscription?.then((handle) => handle.remove());
  }
}

class WebNetworkStatusObserver
  extends NetworkStatusObserverSupport
  implements NetworkStatusObserver
{
  // TODO how to determine initial online status?
  private online: boolean = true;
  private unsubscribe?: () => void;

  start() {
    const handleOnline = () => {
      this.online = true;
      this.update();
    };

    const handleOffline = () => {
      this.online = false;
      this.setStatus({ connectionType: "none", effectiveType: "none" });
    };

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    const timer = window.setInterval(() => {
      this.update();
    }, 10000);

    this.unsubscribe = () => {
      window.clearInterval(timer);
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };

    this.update();
  }

  update() {
    if (this.online) this.setStatus(this.getConnectionType());
  }

  private getConnectionType(): NetworkStatus {
    if (!window.navigator.connection) {
      // Some browsers do not support navigator.connection (Safari and Firefox),
      // so we just assume we're on wifi.
      return {
        connectionType: "unknown",
        effectiveType: "wifi",
        warnings: ["unsupported-api"],
      };
    }

    const connection = window.navigator.connection;

    const type = connection
      ? ((connection as any).type ?? (connection as any).effectiveType)
      : null;

    if (typeof type === "string") {
      switch (type) {
        case "none":
          return { connectionType: type, effectiveType: "none" };
        case "bluetooth":
        case "cellular":
        case "slow-2g":
        case "2g":
        case "3g":
          return { connectionType: type, effectiveType: "cellular" };
        case "ethernet":
        case "wifi":
        case "wimax":
        case "4g":
          return { connectionType: type, effectiveType: "wifi" };
      }
    }

    return { connectionType: type, effectiveType: "unknown" };
  }

  close(): void {
    this.unsubscribe?.();
  }
}
