import { platform } from "../../config";
import type { Settings } from "./types";

export const defaultSettings: Settings = {
  colorScheme: "default",
  fontSize: "medium",
  playAlarmSound: true,
  screensaverEnabled: true,
  screensaverTimeout: 300000,
  coordinatesDisplayTypes: ["wgs-decimal", "wgs-degrees", "utm", "mgrs"],
  speechRate: 1,
  announceNewMission: true,
  announceNewMissionResource: true,
  announceMissionResourceStatusChange: true,
  announceNewMissionReport: true,
  announceNewMissionPatient: true,
  deviceLabel: "identifier",
  tableauResourceListDisplay: "cards",
  experiments: [],
  navigationType: platform === "ios" ? "apple-maps" : "google-maps",
};
