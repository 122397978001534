import { useEffect } from "react";
import { logger } from "../log";
import NoSleep from "../nosleep";
import { useWakeLockStore } from "./store";

const log = logger("wakelog");

export function WakeLockProvider() {
  const setActive = useWakeLockStore((state) => state.setActive);

  useEffect(() => {
    function acquireWakeLock() {
      return (navigator as any).wakeLock
        .request("screen")
        .then((wakeLog: any) => {
          log.info("Wake log is active.");
          wakeLog.addEventListener("release", () => {
            log.info("Wake log was released.");
            setActive(false, "wake-lock-api");
          });
          setActive(true, "wake-lock-api");
        })
        .catch((error: Error) => {
          log.info("Failed to acquire wake lock: %s", error.message);
          setActive(false, "wake-lock-api");
        });
    }

    if (
      "wakeLock" in navigator &&
      typeof (navigator as any).wakeLock.request === "function"
    ) {
      log.info("Requesting wake lock via Wake Lock API");
      acquireWakeLock();
      document.addEventListener("visibilitychange", (evt) => {
        if ((evt.target as any).visibilityState === "visible") {
          log.info("Page re-gained focus, re-enabling wake lock.");
          acquireWakeLock();
        }
      });
      return;
    }

    log.info(
      "Wake Lock API not available or failed, falling back to nosleep.js",
    );
    setActive(false, "nosleep");

    const nosleep = new NoSleep("rescueTABLET")
      .on("enabled", () => {
        log.info("nosleep.js enabled");
        setActive(true, "nosleep");
      })
      .on("error", (error) => {
        log.info("nosleep.js failed:", error);
        setActive(false, "nosleep");
      });

    const events = ["click", "touch"];

    function enableNoSleep() {
      if (!nosleep.isEnabled) {
        log.info("Activating nosleep.js");
        nosleep.enable();
      }

      events.forEach((event) =>
        document.removeEventListener(event, enableNoSleep, false),
      );
    }

    events.forEach((event) =>
      document.addEventListener(event, enableNoSleep, false),
    );
  }, [setActive]);

  return null;
}
