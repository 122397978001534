import { useCallback } from "react";
import { useIgnoredInfosStore, useInfosStore } from "./store";
import type { InfoData } from "./types";

export function useAddInfo() {
  const setIgnored = useIgnoredInfosStore((state) => state.setIgnored);
  const addInfo = useInfosStore((state) => state.addInfo);
  const removeInfo = useInfosStore((state) => state.removeInfo);

  return useCallback(
    (data: InfoData) => {
      const id = data.id;
      const info = {
        ...data,
        dismiss: () => removeInfo(id),
        ignore: () => setIgnored(id, true),
      };
      addInfo(info);
      setIgnored(id, false);
      return info;
    },
    [addInfo, setIgnored, removeInfo],
  );
}

export function useInfos() {
  const infos = useInfosStore((state) => state.infos);
  const ignored = useIgnoredInfosStore((state) => state.ignored) ?? {};
  return infos.filter((info) => !ignored[info.id]);
}
