import { type SpeechSynthesisVoice } from "@capacitor-community/text-to-speech";
import { create } from "zustand";
import type { SpeechOptions } from "./types";

type SpeechOptionsStore = SpeechOptions & {
  setVoices: (voices: ReadonlyArray<SpeechSynthesisVoice>) => void;
};

export const useSpeechOptionsStore = create<SpeechOptionsStore>((set) => ({
  voices: [],
  setVoices: (voices) => set({ voices }),
}));
