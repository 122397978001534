import { type ThemeProviderProps } from "@rescuetablet/theme";
import { useTranslation } from "react-i18next";
import { useSetting } from "./data/settings";

export { ThemeProvider } from "@rescuetablet/theme";

const args = [
  {
    components: {
      MuiCardActions: {
        styleOverrides: { root: { justifyContent: "flex-end" } },
      },
    },
  },
];

export function useUserTheme(): ThemeProviderProps {
  const fontSize = useSetting("fontSize");
  const colorScheme = useSetting("colorScheme");
  const {
    i18n: { language },
  } = useTranslation();

  return {
    fontSize,
    colorScheme,
    language,
    args,
  };
}
