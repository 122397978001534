import { useMemo } from "react";
import { useAuthenticated } from "../auth";
import { portalServer } from "../config";
import {
  type SyncModule,
  useSyncData,
  useSyncModuleRegistration,
} from "../sync";
import type { KartenObjekt } from "../types";
import { limiter } from "./demo";
import { daily } from "./provider";

const syncModuleId = "hydranten";

function useHydrantenSyncModule(): SyncModule<ReadonlyArray<KartenObjekt>> {
  const { portalKey, demo } = useAuthenticated();

  return useMemo(
    () => ({
      id: syncModuleId,
      i18nKey: `karte:tasks.${syncModuleId}`,
      defaultOptions: { schedule: { type: "periodic", interval: daily } },
      fetchData: async () => {
        const response = await fetch(
          `${portalServer}/get-hydrants.php?ORG_ACCESSKEY=${encodeURIComponent(
            portalKey,
          )}`,
        );

        if (!response.ok) {
          throw new Error(
            `Error fetching data: ${response.status} ${response.statusText}`,
          );
        }

        const data = await response.json();
        const limited = limiter<KartenObjekt>(demo, 300);
        return limited(data.HYDRANT_LIST ?? []).map(parseHydrant);
      },
    }),
    [demo, portalKey],
  );
}

export function useHydrantenSync() {
  const module = useHydrantenSyncModule();
  useSyncModuleRegistration(module);
}

export function useHydranten(): ReadonlyArray<KartenObjekt> {
  return useSyncData(syncModuleId) ?? [];
}

function parseHydrant(entry: any): KartenObjekt {
  return {
    id: entry.HYDRANT_ID,
    latitude: parseFloat(entry.HYDRANT_GEO_LAT),
    longitude: parseFloat(entry.HYDRANT_GEO_LNG),
    beschreibung: entry.HYDRANT_DESCRIPTION,
    ...(hydrantTypen[entry.HYDRANT_TYPE] || { typ: "hydrant", name: "" }),
  };
}

const hydrantTypen: { [typ: string]: { typ: string; name: string } } = {
  1: { typ: "hydrant", name: "Unterflurhydrant (H80)" },
  2: { typ: "hydrant", name: "Unterflurhydrant (H100)" },
  7: { typ: "hydrant", name: "Unterflurhydrant (H125)" },
  3: { typ: "hydrant", name: "Unterflurhydrant (H150)" },
  8: { typ: "hydrant", name: "Unterflurhydrant (H200)" },
  16: { typ: "hydrant", name: "Unterflurhydrant (H250)" },
  9: { typ: "hydrant", name: "Unterflurhydrant (H300)" },
  18: { typ: "hydrant", name: "Unterflurhydrant (H350)" },
  10: { typ: "hydrant", name: "Unterflurhydrant (H400)" },
  4: { typ: "hydrant_o", name: "Überflurhydrant (H80)" },
  11: { typ: "hydrant_o", name: "Überflurhydrant (H100)" },
  12: { typ: "hydrant_o", name: "Überflurhydrant (H150)" },
  13: { typ: "hydrant_o", name: "Überflurhydrant (H200)" },
  17: { typ: "hydrant_o", name: "Überflurhydrant (H250)" },
  14: { typ: "hydrant_o", name: "Überflurhydrant (H300)" },
  19: { typ: "hydrant_o", name: "Überflurhydrant (H350)" },
  15: { typ: "hydrant_o", name: "Überflurhydrant (H400)" },
  5: { typ: "hydrant", name: "Offenes Gewässer" },
  6: { typ: "hydrant", name: "Zisterne / Sonstiges" },
  666: {
    typ: "hydrant",
    name: "Mess-, Regel- oder Spülhydrant (nicht für Feuerwehr)",
  },
};
