import { useMemo } from "react";
import { useAuthenticated } from "../auth";
import { useHydranten } from "../db/hydranten";
import { useKartenDetailObjekte } from "../db/kartendetailobjekte";
import { useKartenObjekte } from "../db/kartenobjekte";
import { type Geraet } from "../license";

export function useGeraet(): Geraet {
  return useAuthenticated();
}

export function useKartenobjekte() {
  const hydranten = useHydranten();
  const kartenobjekte = useKartenObjekte();
  const kartendetailobjekte = useKartenDetailObjekte();

  return useMemo(
    () => [...hydranten, ...kartenobjekte, ...kartendetailobjekte],
    [hydranten, kartenobjekte, kartendetailobjekte],
  );
}
