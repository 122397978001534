import { create } from "zustand";
import type { DeviceLocation } from "./types";

type DevicesStore = {
  readonly devices: ReadonlyArray<DeviceLocation>;
  setDevices: (devices: ReadonlyArray<DeviceLocation>) => void;
};

export const useDevicesStore = create<DevicesStore>((set) => ({
  devices: [],
  setDevices: (devices) => set({ devices }),
}));
