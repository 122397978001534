import { create } from "zustand";
import { persist, type PersistOptions } from "zustand/middleware";
import { getStorage } from "../../db/zustand";
import type { Info } from "./types";

type InfosStore = {
  infos: ReadonlyArray<Info>;
  addInfo: (info: Info) => void;
  removeInfo: (id: string) => void;
};

export const useInfosStore = create<InfosStore>((set) => ({
  infos: [],
  addInfo: (info) => set((state) => ({ infos: [...state.infos, info] })),
  removeInfo: (id) =>
    set((state) => ({ infos: state.infos.filter((i) => i.id !== id) })),
}));

type IgnoredInfosStoreData = {
  ignored?: Record<string, boolean>;
};

type IgnoredInfosStore = IgnoredInfosStoreData & {
  setIgnored: (id: string, ignored: boolean) => void;
};

const persistOptions: PersistOptions<IgnoredInfosStore, IgnoredInfosStoreData> =
  {
    name: "config/ignored-infos",
    storage: getStorage(),
    version: 1,
    migrate: (state, version) => {
      console.log("migrate ignored infos:", { state, version });
      switch (version) {
        case 0:
          return { ignored: state as Record<string, boolean> };
        default:
          return {};
      }
    },
  };

export const useIgnoredInfosStore = create(
  persist(
    (set) => ({
      setIgnored: (id, ignored) =>
        set((state) => ({
          ignored: { ...state.ignored, [id]: ignored },
        })),
    }),
    persistOptions,
  ),
);
